import {defaultMediaSearchModel, Media, MediaExtraData, MediaSearchModel} from '@app/media/models';
import {createReducer, on} from '@ngrx/store';
import {MediaActions, MediaApiActions} from '@app/media/state/actions';
import {FeaturedMedia} from '../../models/highlighted-media.model';
import {Collection, defaultCollection} from '@app/shared/models/collection';

export interface State {
  medias: Collection<Media>;
  myMedias: Collection<Media>;

  suggestedMedias: Collection<Media>;
  associatedPlaylists: Collection<Media>;
  topMedias: Collection<Media>;
  continueMedias: Collection<Media>;
  recommendedMedias: Collection<FeaturedMedia>;

  featuredMedias: Collection<FeaturedMedia>;
  featuredMediaExtraData?: MediaExtraData;

  media?: Media;
  mediaExtraData?: MediaExtraData;

  search?: MediaSearchModel;
  searchedMedias: Collection<Media>;

  loading: boolean;
  loaded: boolean;
}

export const INITIAL_STATE: State = {
  medias: defaultCollection(),
  myMedias: defaultCollection(),
  featuredMedias: defaultCollection(),
  suggestedMedias: defaultCollection(),
  associatedPlaylists: defaultCollection(),
  topMedias: defaultCollection(),
  continueMedias: defaultCollection(),
  recommendedMedias: defaultCollection(),
  searchedMedias: defaultCollection(),
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  INITIAL_STATE,
  on(
    MediaActions.loadMedias,
    MediaActions.loadSuggestedMedias,
    MediaActions.loadTopMedias,
    MediaActions.likeMedia,
    MediaActions.unlikeMedia,
    MediaActions.loadContinueMedias,
    state => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),
  on(MediaActions.loadMyMedias, state => ({
    ...state,
    myMedias: defaultCollection<Media>(),
    loading: true,
    loaded: false
  })),
  on(MediaActions.loadMedia, (state, {reset}) => ({
    ...state,
    media: reset ? undefined : state.media,
    loading: true,
    loaded: false
  })),
  on(MediaActions.loadMediaExtraData, (state, {reset}) => ({
    ...state,
    mediaExtraData: reset ? undefined : state.mediaExtraData,
    loading: true,
    loaded: false
  })),
  on(MediaActions.loadFeaturedMedias, state => ({
    ...state,
    featuredMedias: defaultCollection<FeaturedMedia>(),
    featuredMediaExtraData: undefined,
    loading: true,
    loaded: false
  })),
  on(MediaActions.loadFeatureMediaExtraData, state => ({
    ...state,
    featuredMediaExtraData: undefined,
    loading: true,
    loaded: false
  })),
  on(MediaActions.loadAssociatedPlaylists, state => ({
    ...state,
    associatedPlaylists: defaultCollection<Media>(),
    loading: true,
    loaded: false
  })),
  on(MediaActions.searchMedias, (state, {search}) => ({
    ...state,
    search,
    searchedMedias:
      search.page !== 1 ? state.searchedMedias : defaultCollection<Media>(),
    loading: true,
    loaded: false
  })),
  // Api actions
  on(MediaApiActions.loadMediasSuccess, (state, {medias}) => ({
    ...state,
    medias,
    loading: false,
    loaded: true
  })),
  on(MediaApiActions.loadSuggestedMediasSuccess, (state, {medias}) => ({
    ...state,
    suggestedMedias: medias,
    loading: false,
    loaded: true
  })),
  on(MediaApiActions.loadMyMediasSuccess, (state, {medias}) => ({
    ...state,
    myMedias: medias,
    loading: false,
    loaded: true
  })),
  on(MediaApiActions.loadTopMediasSuccess, (state, {medias}) => ({
    ...state,
    topMedias: medias,
    loading: false,
    loaded: true
  })),
  on(MediaApiActions.loadContinueMediasSuccess, (state, {medias}) => ({
    ...state,
    continueMedias: medias,
    loading: false,
    loaded: true
  })),
  on(MediaApiActions.loadRecommendedMediasSuccess, (state, {medias}) => ({
    ...state,
    recommendedMedias: medias,
    loading: false,
    loaded: true
  })),
  on(MediaApiActions.loadAssociatedPlaylistsSuccess, (state, {medias}) => ({
    ...state,
    associatedPlaylists: medias,
    loading: false,
    loaded: true
  })),
  on(MediaApiActions.loadMediaSuccess, (state, {media}) => ({
    ...state,
    media,
    loading: false,
    loaded: true
  })),
  on(
    MediaApiActions.loadMediaExtraDataSuccess,
    (state, {mediaExtraData}) => ({
      ...state,
      mediaExtraData,
      loading: false,
      loaded: true
    })
  ),
  on(
    MediaApiActions.loadFeaturedMediasSuccess,
    (state, {featuredMedias}) => ({
      ...state,
      featuredMedias,
      loading: false,
      loaded: true
    })
  ),
  on(
    MediaApiActions.loadFeaturedMediaExtraDataSuccess,
    (state, {mediaExtraData}) => ({
      ...state,
      featuredMediaExtraData: mediaExtraData,
      loading: false,
      loaded: true
    })
  ),
  on(MediaApiActions.likeMediaSuccess, (state, {likeCount}) => ({
    ...state,
    media:
      state.media !== undefined
        ? {
          ...state.media,
          likeCount
        }
        : state.media,
    mediaExtraData:
      state.mediaExtraData !== undefined
        ? {
          ...state.mediaExtraData,
          extraData: {
            ...state.mediaExtraData.extraData,
            liked: true
          }
        }
        : state.mediaExtraData,
    loading: false,
    loaded: true
  })),
  on(MediaApiActions.unlikeMediaSuccess, (state, {likeCount}) => ({
    ...state,
    media:
      state.media !== undefined
        ? {
          ...state.media,
          likeCount
        }
        : state.media,
    mediaExtraData:
      state.mediaExtraData !== undefined
        ? {
          ...state.mediaExtraData,
          extraData: {
            ...state.mediaExtraData.extraData,
            liked: false
          }
        }
        : state.mediaExtraData,
    loading: false,
    loaded: true
  })),
  on(MediaApiActions.searchMediasSuccess, (state, {medias}) => ({
    ...state,
    searchedMedias: {
      count: state.searchedMedias.count + medias.count,
      items:
        state.search && state.search.page === 1
          ? medias.items
          : [...state.searchedMedias.items, ...medias.items]
    },
    search: {
      ...(state.search ? state.search : defaultMediaSearchModel()),
      hasMore: medias.items.length > 0
    },
    loading: false,
    loaded: true
  })),
  on(MediaApiActions.loadMediasFailure, state => ({
    ...state,
    medias: defaultCollection<Media>(),
    loading: false,
    loaded: false
  })),
  on(MediaApiActions.loadMyMediasFailure, state => ({
    ...state,
    myMedias: defaultCollection<Media>(),
    loading: false,
    loaded: true
  })),
  on(MediaApiActions.loadSuggestedMediasFailure, state => ({
    ...state,
    suggestedMedias: defaultCollection<Media>(),
    loading: false,
    loaded: false
  })),
  on(MediaApiActions.loadTopMediasFailure, state => ({
    ...state,
    topMedias: defaultCollection<Media>(),
    loading: false,
    loaded: false
  })),
  on(MediaApiActions.loadContinueMediasFailure, state => ({
    ...state,
    continueMedias: defaultCollection<Media>(),
    loading: false,
    loaded: false
  })),
  on(MediaApiActions.loadRecommendedMediasFailure, state => ({
    ...state,
    recommendedMedias: defaultCollection<FeaturedMedia>(),
    loading: false,
    loaded: false
  })),
  on(MediaApiActions.loadMediaFailure, state => ({
    ...state,
    media: undefined,
    loading: false,
    loaded: false
  })),
  on(MediaApiActions.loadMediaExtraDataFailure, state => ({
    ...state,
    mediaExtraData: undefined,
    loading: false,
    loaded: false
  })),
  on(MediaApiActions.loadFeaturedMediasFailure, state => ({
    ...state,
    featuredMedias: defaultCollection<FeaturedMedia>(),
    loading: false,
    loaded: false
  })),
  on(MediaApiActions.loadAssociatedPlaylistsFailure, state => ({
    ...state,
    associatedPlaylists: defaultCollection<Media>(),
    loading: false,
    loaded: false
  })),
  on(MediaApiActions.loadFeaturedMediaExtraDataFailure, state => ({
    ...state,
    featuredMediaExtraData: undefined,
    loading: false,
    loaded: false
  })),
  on(MediaApiActions.searchMediasFailure, state => ({
    ...state,
    searchedMedias: defaultCollection<Media>(),
    loading: false,
    loaded: false
  })),
  on(
    MediaApiActions.likeMediaFailure,
    MediaApiActions.unlikeMediaFailure,
    state => ({
      ...state,
      loading: false,
      loaded: false
    })
  )
);

export const getSearch = (state: State) => state.search;
export const getMedias = (state: State) => state.medias;
export const getMyMedias = (state: State) => state.myMedias;
export const getSuggestedMedias = (state: State) => state.suggestedMedias;
export const getTopMedias = (state: State) => state.topMedias;
export const getContinueMedias = (state: State) => state.continueMedias;
export const getRecommendedMedias = (state: State) => state.recommendedMedias;
export const getFeaturedMedias = (state: State) => state.featuredMedias;
export const getSearchedMedias = (state: State) => state.searchedMedias;
export const getAssociatedPlaylists = (state: State) => state.associatedPlaylists;
export const getMedia = (state: State) => state.media;
export const getMediaExtraData = (state: State) => state.mediaExtraData;
export const getFeaturedMediaExtraData = (state: State) =>
  state.featuredMediaExtraData;
export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
