export const environment = {
  production: false,
  lang: ['fr'],
  apiHost: 'https://staging.mct.monsieursloop.com',
  apiBasePath: '/api',
  apiPublicBasePath: '/public',
  apiSuffix: '',
  prefixAsset: '',
  gtmId: 'G-2ZSNYM94R1',
  // gtmUaId: 'UA-183970154-1',
  apiVideoHost: 'https://relay.mycampustube.fr/relay/ws.api.video'
};
