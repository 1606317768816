import {Injectable} from '@angular/core';
import {fromEvent} from 'rxjs';
import {filter, first, map, tap} from 'rxjs/operators';
import {PlayerSdk} from '@api.video/player-sdk';

@Injectable({
  providedIn: 'root'
})
export class ApiVideoService {
  windowMessage$ = fromEvent<MessageEvent>(window, 'message');

  eventsForPlayer(internalPlayerId: number) {
    return this.windowMessage$.pipe(
      filter(message => message.data?.sdkPlayerId === internalPlayerId.toString()),
      map(message => message.data?.type as string)
    );
  }

  preventAutoPlayMute(internalPlayerId: number, player: PlayerSdk) {
    return this.windowMessage$.pipe(
      filter(message => message.data?.sdkPlayerId === internalPlayerId.toString() && message.data?.type === 'ready'),
      tap(message =>
        ((player as any).iframe.contentWindow as Window).postMessage({
          sdkPlayerId: internalPlayerId,
          message: 'unmute'
        }, message.origin)
      ),
      first()
    );
  }
}
