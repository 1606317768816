import { Component, OnInit } from '@angular/core';
import { MediaFacade } from '@app/media/state/media.facade';
import { ActivatedRoute, Router } from '@angular/router';
import { defaultMediaSearchModel, MediaSearchOrder } from '@app/media/models';
import { MediaResearchFormValue } from '@app/media/components/media-research-form/media-research-form.component';
import { combineLatest } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GtagService } from '@app/core/services/gtag.service';
import {
  defaultMediaSearchConfig,
  MediaSearchService
} from '@app/media/services/media.search.service';

@UntilDestroy()
@Component({
  selector: 'app-medias-search',
  templateUrl: './medias-search.component.html',
  styleUrls: ['./medias-search.component.scss']
})
export class MediasSearchComponent implements OnInit {
  loading$ = this.mediaFacade.loading$;
  searchedMedias$ = this.mediaFacade.searchedMedias$;
  searchedMediasCount$ = this.mediaFacade.searchedMediasCount$;

  empty$ = combineLatest([
    this.mediaFacade.loading$,
    this.mediaFacade.search$,
    this.mediaFacade.searchedMediasCount$
  ]).pipe(
    map(
      ([loading, search, count]) =>
        !loading && !!search && !search.hasMore && count === 0
    )
  );

  searchForm$ = this.mediaSearchService.searchForm$;
  searchConfig$ = this.mediaSearchService.config$;

  constructor(
    private mediaFacade: MediaFacade,
    private route: ActivatedRoute,
    private router: Router,
    private mediaSearchService: MediaSearchService,
    private gtag: GtagService
  ) {}

  ngOnInit() {
    this.mediaSearchService.changeConfig(defaultMediaSearchConfig());

    this.mediaSearchService.search$
      .pipe(untilDestroyed(this))
      .subscribe(search => {
        this.mediaFacade.searchMedias(search);
      });

    this.route.queryParams.subscribe(params => {
      // Gtag event
      this.gtag.event({
        action: 'search',
        value: params.query ? params.query : ''
      });
    });
  }

  search(value: MediaResearchFormValue) {
    this.mediaSearchService.search(value, ['/medias/search']);
  }

  loadMore() {
    combineLatest([this.mediaFacade.search$, this.mediaFacade.loading$])
      .pipe(
        first(),
        filter(([search, loading]) => !loading && !!search && search.hasMore)
      )
      .subscribe(([search]) => {
        search = search ? search : defaultMediaSearchModel();
        this.mediaFacade.searchMedias({
          ...search,
          page: search.page + 1
        });
      });
  }
}
