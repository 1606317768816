<div
  class="media-view"
  [ngSwitch]="mediaType"
  *ngIf="mediaType !== undefined && !!extraData; else image"
>
  <!-- PDF -->
  <ng-container *ngSwitchCase="MediaType.PDF">
    <app-media-pdf-viewer
      *ngIf="pMedia && pMedia.pdfUrl !== null; else image"
      [pdfUrl]="pMedia.pdfUrl"
    ></app-media-pdf-viewer>
  </ng-container>

  <!-- VIDEO -->
  <ng-container *ngSwitchCase="MediaType.VIDEO">
    <iframe
      *ngIf="videoUrl !== undefined"
      [id]="playerId"
      class="media-video"
      [src]="iframeSrc"
      width="100%"
      height="100%"
      frameborder="0"
      allowfullscreen
      allow="autoplay"
    ></iframe>
    <video
      *ngIf="videoUrl2 !== undefined"
      [id]="playerId"
      width="100%"
      height="100%"
      controls
      autoplay
    >
      <source [src]="videoUrl2" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </ng-container>
  <ng-content></ng-content>
</div>

<!-- OVERLAY -->
<ng-template #image>
  <div
    *ngIf="imageUrl; else color"
    (click)="needExtraData.emit()"
    [style.background-image]="'url(' + imageUrl + ')'"
    class="media-image"
  >
  </div>

  <!-- Video resume and duration -->
  <div
    *ngIf="pMedia && mediaType === MediaType.VIDEO"
    (click)="needExtraData.emit()"
    class="video-overlay"
  >
    <div class="video-progress" *ngIf="getProgress(pMedia) > 5">
      <div [style.width.%]="getProgress(pMedia)"></div>
    </div>
    <div class="video-duration-wrapper">
      <div class="video-duration">
        <span [appFitText]="true">{{getDuration(pMedia)}}</span>
      </div>
    </div>
  </div>

  <!-- Playlist overlay -->
  <div
    *ngIf="pMedia && mediaType === MediaType.PLAYLIST"
    class="playlist-overlay flex-column align-items-center justify-content-center"
  >
    <div class="count">
      <span [appFitText]="true">{{pMedia.playlistItemsCount}}</span>
    </div>
    <div>
      <span [appFitText]="true">{{pMedia.playlistItemsCount > 1 ? 'Médias' : 'Média'}}</span>
    </div>
  </div>
</ng-template>

<ng-template #color>
  <div (click)="needExtraData.emit()" class="media-default"></div>
</ng-template>
