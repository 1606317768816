import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {MediaService} from '@app/media/services/media.service';
import {MediaActions, MediaApiActions} from '@app/media/state/actions';
import {CommentActions} from '@app/comment/state/actions';
import {Iri} from '@app/shared/models/api-entity';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as fromMedia from '@app/media/state/reducers/media.reducer';
import {AuthFacade} from '@app/authentication/state/auth.facade';
import {ChannelFacade} from '../../../channel/state/channel-facade.service';
import {ChannelApiActions} from '../../../channel/state/actions';
import {MediaFacade} from '../media.facade';
import {defaultMediaSearchModel, Media} from '../../models';

@Injectable()
export class MediaEffects {
  loadMedias$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.loadMedias),
      switchMap(() => {
        return this.service.getMedias().pipe(
          map(medias => MediaApiActions.loadMediasSuccess({medias})),
          catchError(error => {
            return of(
              MediaApiActions.loadMediasFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  loadAssociatedPlaylists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.loadAssociatedPlaylists),
      switchMap(({id}) => {
        return this.service.getMedias({
          'playlistItems.mediaItem': id.toString()
        }).pipe(
          map(medias => MediaApiActions.loadAssociatedPlaylistsSuccess({medias})),
          catchError(error => {
            return of(
              MediaApiActions.loadAssociatedPlaylistsFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  loadMyMedias$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.loadMyMedias),
      switchMap(({search}) => {
        return this.service.getMyMedias(search).pipe(
          map(medias => MediaApiActions.loadMyMediasSuccess({medias})),
          catchError(error => {
            return of(
              MediaApiActions.loadMyMediasFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  loadSuggestedMedias$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.loadSuggestedMedias),
      switchMap(() => {
        return this.service.loadSuggestedMedias().pipe(
          map(medias => MediaApiActions.loadSuggestedMediasSuccess({medias})),
          catchError(error => {
            return of(
              MediaApiActions.loadSuggestedMediasFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  loadTopMedias$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.loadTopMedias),
      switchMap(() => {
        return this.service.loadTopMedias().pipe(
          map(medias => MediaApiActions.loadTopMediasSuccess({medias})),
          catchError(error => {
            return of(
              MediaApiActions.loadTopMediasFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  loadContinueMedias$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.loadContinueMedias),
      switchMap(() => {
        return this.service.getResumeMedias(defaultMediaSearchModel()).pipe(
          map(medias => MediaApiActions.loadContinueMediasSuccess({medias})),
          catchError(error => {
            return of(
              MediaApiActions.loadContinueMediasFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  loadRecommendedMedias$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.loadRecommendedMedias),
      switchMap(() => {
        return this.service.loadRecommendedMedias().pipe(
          map(medias => MediaApiActions.loadRecommendedMediasSuccess({medias})),
          catchError(error => {
            return of(
              MediaApiActions.loadRecommendedMediasFailure({error: error.message})
            );
          })
        );
      })
    )
  );

  loadMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.loadMedia),
      switchMap(({id}) => {
        return this.service.getMedia(id).pipe(
          map(media => MediaApiActions.loadMediaSuccess({media})),
          catchError(error => {
            return of(MediaApiActions.loadMediaFailure({error}));
          })
        );
      })
    )
  );

  reloadMedia$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ChannelApiActions.followSuccess,
        ChannelApiActions.unfollowSuccess
      ),
      withLatestFrom(this.mediaFacade.media$),
      map(([_, media]) => media),
      filter((media): media is Media => !!media?.id),
      map(media => MediaActions.loadMedia({id: media.id, reset: false}))
    )
  );

  loadMediaExtraData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.loadMediaExtraData),
      switchMap(({id, highlightId}) => {
        return this.service.loadMediaExtraData(id, highlightId).pipe(
          map(mediaExtraData =>
            MediaApiActions.loadMediaExtraDataSuccess({mediaExtraData})
          ),
          catchError(error => {
            return of(
              MediaApiActions.loadMediaExtraDataFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  loadMediaComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaApiActions.loadMediaSuccess),
      map(({media}) =>
        CommentActions.loadComments({mediaIri: media['@id'] as Iri})
      )
    )
  );

  resetMediaComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.loadMedia),
      map(() => CommentActions.resetComments())
    )
  );

  loadFeaturedMedias$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.loadFeaturedMedias),
      switchMap(() => {
        return this.service.loadFeaturedMedias().pipe(
          map(featuredMedias =>
            MediaApiActions.loadFeaturedMediasSuccess({featuredMedias})
          ),
          catchError(error => {
            return of(
              MediaApiActions.loadFeaturedMediasFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  loadFeatureMediaExtraData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.loadFeatureMediaExtraData),
      switchMap(({id}) => {
        return this.service.loadMediaExtraData(id).pipe(
          map(mediaExtraData =>
            MediaApiActions.loadFeaturedMediaExtraDataSuccess({
              mediaExtraData
            })
          ),
          catchError(error => {
            return of(
              MediaApiActions.loadFeaturedMediaExtraDataFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  searchMedias$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.searchMedias),
      switchMap(({search}) => {
        return this.service.searchMedias(search).pipe(
          map(medias => MediaApiActions.searchMediasSuccess({medias})),
          catchError(error => {
            return of(
              MediaApiActions.searchMediasFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  notFoundMedia$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MediaApiActions.loadMediaFailure),
        filter(
          ({error}) =>
            error instanceof HttpErrorResponse && error.status === 404
        ),
        tap(({error}) => this.router.navigate(['/fallback', 'not-found']))
      ),
    {dispatch: false}
  );

  likeMedia = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.likeMedia),
      switchMap(({id}) => {
        return this.service.likeMedia(id).pipe(
          map(props => MediaApiActions.likeMediaSuccess(props)),
          catchError(error => {
            return of(
              MediaApiActions.likeMediaFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  unlikeMedia = createEffect(() =>
    this.actions$.pipe(
      ofType(MediaActions.unlikeMedia),
      switchMap(({id}) => {
        return this.service.unlikeMedia(id).pipe(
          map(props => MediaApiActions.unlikeMediaSuccess(props)),
          catchError(error => {
            return of(
              MediaApiActions.unlikeMediaFailure({
                error: error.message
              })
            );
          })
        );
      })
    )
  );

  public constructor(
    private actions$: Actions,
    private store: Store<fromMedia.State>,
    private service: MediaService,
    private router: Router,
    private auth: AuthFacade,
    private channelFacade: ChannelFacade,
    private mediaFacade: MediaFacade
  ) {
  }
}
