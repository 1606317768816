import {Comment} from '@app/comment/models/comment.model';
import {MediaAttachment} from '@app/media/models/media-attachment.model';
import {Category} from '@app/category/models';
import {User} from '@app/user/models/user.model';
import {MediaLike} from '@app/media/models/media-like.model';
import {ApiEntity, Iri} from '@app/shared/models/api-entity';
import {Tag} from '@app/tag/models/tag.model';

export enum MediaType {
  VIDEO = 'video',
  PDF = 'pdf',
  PLAYLIST = 'playlist'
}

export enum MediaVisibility {
  PUBLIC = 'public', // Public and indexed (searchable)
  PRIVATE = 'private', // Private, can't be accessed by others
  UNLISTED = 'unlisted' // Public but not searchable, only reachable through the url
}

export interface Media extends ApiEntity {
  id: number;
  title: string;
  description: string;
  creator: User | Iri;
  imageName: string;
  imageUrl: string;
  createdAt: string;
  updatedAt: string;
  viewCount: number;
  likeCount: number;
  likes: MediaLike[];
  categories: Category[];
  tags: Tag[];
  comments: Comment[];
  type: MediaType;
  visibility: MediaVisibility;
  attachments: MediaAttachment[];
  isFeatured: boolean;
  imageFile: Iri | null;
  reportUserIds: [];

  // PDF
  pdfFile: Iri | null;
  pdfUrl: string | null;

  // Video
  apiVideoId: string | null;
  apiVideoImageName: string | null;
  duration: number;
  isPlayable: boolean;
  currentUserLastVideoPosition: number;
  currentUserHasCompletedVideo: boolean;

  // Playlist
  playlistItems: PlaylistItem[];
  playlistItemsCount: number;
}

export const defaultMedia = (): Partial<Media> => ({
  title: '',
  description: '',
  type: MediaType.VIDEO,
  visibility: MediaVisibility.PUBLIC,
  tags: [],
  categories: [],
  attachments: [],
  comments: [],
  playlistItems: [],
  playlistItemsCount: 0
});

export interface PlaylistItem {
  mediaItemId: number;
  position: number;
  mediaItem: Media;
}

export interface MediaExtraData extends ApiEntity {
  extraData: {
    status: string | null;
    iframePlayerUrl: string;
    iframePlayerUrl2: string;
    videoPosition: number;
    liked: boolean;
  };
  imageUrl: string;
  pdfUrl: string | null;
}
